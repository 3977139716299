<template>
  <div >
    <div class="flex-col page" :class="sec?'actives':''">
      
      <div class="flex-row pages">
        <div  class="left">
        <div class="flex-col space-y-72 group">
          <!-- @mouseleave="show=false" -->
          <div @mouseleave="show=false"  class="flex-col space-y-104 group_2">

            <div  @mouseenter="show=true" class="flex-col section">
              <div class="group_3">
                
                <span class="font_2 text_3">{{title[selected][0]}}</span>
                <span class="font_2 text_4">{{title[selected][1]}}</span>
              </div>
            </div>
            <div v-show="show" class="select">
              <tab v-model="selected" :selected="selected"/>
            </div>
          </div>
          <div class="flex-col items-start group_4">
            <img style="opacity: 0;" class="image_2"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16637555903116215022.png" />
            <span class="text_5">{{list[selected].cars[list[selected].index].color}}</span>
            <img class="image"
           
              :src="list[selected].cars[list[selected].index].carurl" />
          </div>
        </div>
        <div class="flex-row justify-center group_5">
          <img v-for="(t,i) in list[selected].cars" class="image_3"  @click="setcolor(i)"
            :src="t.colorurl" />
        </div>
        <div class="flex-col space-y-37 group_6">
          <span class="font_3 text_6" :style="{opacity:sec?'0':'1'}">车体与悬吊参数</span>
          <div class="divider"></div>
        </div>
        <div class="flex-col group_7">
          <div class="flex-col items-start space-y-27 group_8">
            <span class="font_1">长*宽*高（mm）</span>
            <span class="font_4">{{list[selected].ct[0]}}</span>
          </div>
          <div class="flex-col items-start space-y-29 group_8 view">
            <span class="font_1">轴距（mm）</span>
            <span class="font_5">{{list[selected].ct[1]}}</span>
          </div>
          <div class="flex-col items-start space-y-27 group_9">
            <span class="font_1 text_7">整备重量（kg）</span>
            <span class="font_5">{{list[selected].ct[2]}}</span>
          </div>
          <div class="flex-col items-start space-y-29 group_10">
            <span class="font_1">仪表</span>
            <span class="font_5">{{list[selected].ct[3]}}</span>
          </div>
          <div class="flex-col items-start space-y-26 group_8 view_2">
            <span class="font_1">刹车型式</span>
            <span class="font_6">{{list[selected].ct[4]}}</span>
          </div>
          <div class="flex-col items-start space-y-28 group_11">
            <span class="font_1">前轮胎规格</span>
            <span class="font_4 text_8">{{list[selected].ct[5]}}</span>
          </div>
          <div class="flex-col items-start space-y-28 group_12">
            <span class="font_1">后轮胎规格</span>
            <span class="font_4 text_9">{{list[selected].ct[6]}}</span>
          </div>
         

          
        </div>
        <div class="flex-col space-y-37 group_6">
          <span :style="{opacity:sec?'0':'1'}" class="font_3 text_6">动力与性能参数</span>
          <div class="divider"></div>
        </div>
        <div class="flex-col group_13">
          <div class="flex-col items-start space-y-26 group_8">
            <span class="font_1 text_11">电机型式</span>
            <span class="font_6">{{list[selected].dl[0]}}</span>
          </div>
          <div class="flex-col items-start space-y-29 group_14 view_3">
            <span class="font_1">额定功率（W）</span>
            <span class="font_5">{{list[selected].dl[1]}}</span>
          </div>
          <div class="flex-col items-start space-y-29 group_15 view_4">
            <span class="font_1">最大功率（W）</span>
            <span class="font_5">{{list[selected].dl[2]}}</span>
          </div>
          <div class="flex-col items-start space-y-29 group_8 view_5">
            <span class="font_1 text_12">最高车速（km/h）</span>
            <span class="font_5">{{list[selected].dl[3]}}</span>
          </div>
          <div class="flex-col items-start space-y-26 group_15 view_6">
            <span class="font_1">续航力（km）</span>
            <span class="font_6">{{list[selected].dl[4]}}</span>
          </div>
          <div class="flex-col items-start space-y-26 group_14 view_7">
            <span class="font_1">电池管理系统</span>
            <span class="font_6">{{list[selected].dl[5]}}</span>
          </div>
          <div class="flex-col items-start space-y-26 group_8 view_8">
            <span class="font_1 text_13">电芯规格</span>
            <span class="font_6">{{list[selected].dl[6]}}</span>
          </div>
          <div class="flex-col items-start space-y-27 group_16">
            <span class="font_1 text_14">电池容量</span>
            <span class="font_4">{{list[selected].dl[7]}}</span>
          </div>
          
        </div>
        <div class="flex-col space-y-37 group_6">
          <span :style="{opacity:sec?'0':'1'}" class="font_3 text_6">智能与便捷性</span>
          <div class="divider"></div>
        </div>
        <div class="flex-col space-y-47 group_17">
          <div class="flex-col items-start space-y-32 group_8">
            <span class="font_1 text_16">APP</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_14">
            <span class="font_1">智能定位</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_14">
            <span class="font_1">智能报警</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_14">
            <span class="font_1">遥控钥匙</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_15">
            <span class="font_1">无钥匙进入</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_18">
            <span class="font_1">遥控开启座垫</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_14">
            <span class="font_1">斜撑断电</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29 group_8">
            <span class="font_1">高保真音效</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29">
            <span class="font_1 text_17">自动大灯</span>
            <span class="font_7">●</span>
          </div>
          <div class="flex-col items-start space-y-29">
            <span class="font_1">换电</span>
            <span class="font_7">●</span>
          </div>
        </div>
      </div>
      </div>
     
    </div>
  </div>
</template>

<script>
import tab from "./tab.vue"
export default {
  name: 'Vue3Constart',
  watch:{
    selected(){
      this.show=false;
    }
  },
  props:["sec","selected"],
    data() {
    return {
       title:{
        "s7":['赛道级电摩','S7'],
        "ilikes":["智能复古电摩","i-Like"],
        "ionex":['爱玩标准版','i-OneX'],
        "ionexs":['爱玩运动版','i-OneXS'],
        "f9":['高性能仿赛街跑','F9'],
         
       },
       show:false,
       
       list:
       
       {
        's7':{
        index:0,
        cars:[
          {colorurl:require("@/assets/images/car/s7/c1.png") ,color:'现代灰',carurl:require("@/assets/images/car/s7/car1.png") },
          {colorurl:require("@/assets/images/car/s7/c2.png") ,color:'现代灰',carurl:require("@/assets/images/car/s7/car1.png") }
        ],
        ct:["1810*690*1115","1280","121","LCD","前后碟刹","110/70-13","120/70-12"],
        dl:["中置电机","1200","7200","92","155km（等速30km/h）","ionex®️高能动力锂电池BMS","21700汽车级电芯","50.8V/34.3Ah"],
        
       },
       'ionexs':{
        index:0,
        cars:[
        {colorurl:require("@/assets/images/car/xs/c1.png") ,color:'天使白',carurl:require("@/assets/images/car/xs/car1.png") },
        {colorurl:require("@/assets/images/car/xs/c2.png") ,color:'天使白',carurl:require("@/assets/images/car/xs/car1.png") },
        {colorurl:require("@/assets/images/car/xs/c3.png") ,color:'天使白',carurl:require("@/assets/images/car/xs/car1.png") },
        {colorurl:require("@/assets/images/car/xs/c4.png") ,color:'天使白',carurl:require("@/assets/images/car/xs/car1.png") }
        ],
        ct:["1785*720*1095","1285","90","LCD","前后碟刹",'90/70-12','90/80-12'],
        dl:["轮毂电机",'1500','2000',"49","65km（等速30km/h）","ionex®️高能动力锂电池BMS","21700汽车级电芯","48V/28Ah"]
       },
       'ilikes':{
        index:0,
        cars:[
        {colorurl:require("@/assets/images/car/ilike/c1.png") ,color:'冰川蓝',carurl:require("@/assets/images/car/ilike/car1.png") },
        {colorurl:require("@/assets/images/car/ilike/c2.png") ,color:'冰川蓝',carurl:require("@/assets/images/car/ilike/car1.png") }
        ],
        ct:["1920*660*1125","1320","103","LCD","前碟后鼓",'110/70-12','120/70-12'],
        dl:["侧挂电机",'2000','3000',"64.2","70km（等速30km/h）","ionex®️高能动力锂电池BMS","21700汽车级电芯","48V35Ah"]
       },
       'ionex':{
        index:0,
        cars:[
        {colorurl:require("@/assets/images/car/x/c1.png") ,color:'雾海蓝',carurl:require("@/assets/images/car/x/car1.png") },
        {colorurl:require("@/assets/images/car/x/c2.png") ,color:'莫兰迪粉',carurl:require("@/assets/images/car/x/car2.png") },
        {colorurl:require("@/assets/images/car/x/c3.png") ,color:'熔岩红 时光白',carurl:require("@/assets/images/car/x/car3.png") },
        {colorurl:require("@/assets/images/car/x/c4.png") ,color:'暗夜黑',carurl:require("@/assets/images/car/x/car4.png") },
        ],
        ct:["1730*720*1090","1280","84","LCD","前碟后鼓",'90/90-10','90/90-10'],
        dl:["轮毂电机",'1200','1500',"49","65km（等速30km/h）","ionex®️高能动力锂电池BMS","21700汽车级电芯","48V28Ah"]
       },
       'f9':{
        index:0,
        cars:[
        {colorurl:require("@/assets/images/car/f9/c1.png") ,color:'魔力红',carurl:require("@/assets/images/car/f9/car1.png") }
        ],
        ct:["1831*714*1085","1236","113","LCD","前碟后鼓",'110/80-14','140/70-14'],
        dl:["中置电机",'4800','9600',"110","180km（等速30km/h）","ionex®️高能动力锂电池BMS","21700汽车级电芯","96V/44Ah"]
       },
      }
    };
  },
  components:{
    tab
  },
  mounted() {
    console.log(this.$route.name)
  },

  methods: {
    setcolor(index){
      this.$set(this.list[this.selected],"index",index)
    }
  },
};
</script>

<style scoped>
  .section{
    position: relative;
  }
  .select{
     position: absolute;
     z-index: 99;
     top: 0;
     left: 0;
     margin-top: 0 !important;
     left: 160px;
  }
 
  .pages{
    width: 100%;
    overflow: hidden;
  }
.page {
  padding:0 ;
  background-color: #f2f2f2;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group {
  align-self: flex-start;
  width: 687px;
}

.space-y-72>*:not(:first-child) {
  margin-top: 72px;
}

.group_2 {
  
  position: relative;
}

.space-y-104>*:not(:first-child) {
  margin-top: 104px;
}

.space-y-18>*:not(:first-child) {
  margin-top: 18px;
}

.text {
  color: #000;
  font-size: 48px;
  
  font-weight: 700;
  line-height: 44px;
}

.font_1 {
  font-size: 24px;
  
  line-height: 23px;
  font-weight: 700;
  color: #000;
}

.text_2 {
  margin-right: 3px;
  font-weight: unset;
}

.section {
  padding: 23px 0 22px;
  align-self: center;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
}

.group_3 {
  margin-left: 28px;
  margin-right: 24px;
  line-height: 25px;
  text-align: center;
}

.font_2 {
  font-size: 26px;
  
  color: #000;
}

.text_3 {
  line-height: 25px;
}

.text_4 {
  font-weight: 700;
  line-height: 21px;
}

.group_4 {
  padding: 315px 0 16px;
  position: relative;
}

.image_2 {
  width: 547px;
  height: 127px;
}

.text_5 {
  color: #000;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
  position: absolute;
  left: 290px;
  bottom: 0;
  font-family: Albbr;
}

.image {
  
  height: 444px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.group_5 {
  margin-left: 180px;
  margin-top: 43px;
  align-self: flex-start;
  width: 335px;
}

.image_3 {
  width: 51px;
  height: 51px;
  margin: 0 10px;
}

.image_4 {
  width: 50px;
  height: 50px;
}

.group_6 {
  margin-top: 120px;
  padding: 0 108px;
}

.space-y-37>*:not(:first-child) {
  margin-top: 37px;
}

.font_3 {
  font-size: 38px;
  
  line-height: 35px;
  color: #000;
}

.text_6 {
  margin-left: 29px;
  align-self: flex-start;
}

.divider {
  margin-right: 8px;
  background-color: #bfbfbf;
  height: 1px;
  width: 300%;
}

.group_7 {
  margin: 59px 108px 0;
  padding: 0 27px 36px;
 
}

.group_8 {
  padding: 0 2px;
}

.font_4 {
  font-size: 18px;
  
  line-height: 23px;
  font-weight: 300;
  color: #000;
}

.view {
  margin-top: 47px;
}

.font_5 {
  font-size: 18px;
  
  line-height: 21px;
  font-weight: 300;
  color: #000;
}

.group_9 {
  margin-top: 47px;
}

.text_7 {
  line-height: 25px;
}

.group_10 {
  margin-top: 47px;
}

.view_2 {
  margin-top: 46px;
}

.font_6 {
  font-size: 18px;
  
  line-height: 26px;
  font-weight: 300;
  color: #000;
}

.group_11 {
  margin-top: 47px;
}

.text_8 {
  line-height: 22px;
}

.group_12 {
  margin-top: 47px;
}

.space-y-28>*:not(:first-child) {
  margin-top: 28px;
}

.text_9 {
  line-height: 22px;
}

.text_10 {
  margin-top: 144px;
  align-self: flex-start;
  line-height: 36px;
}

.group_13 {
  margin: 59px 108px 0;
  padding: 0 25px 37px;
  
}

.text_11 {
  margin-left: 3px;
}

.group_14 {
  padding: 0 3px;
}

.view_3 {
  margin-top: 45px;
}

.group_15 {
  padding: 0 4px;
}

.view_4 {
  margin-top: 47px;
}

.view_5 {
  margin-top: 47px;
}

.text_12 {
  margin-left: 3px;
}

.view_6 {
  margin-top: 46px;
}

.view_7 {
  margin-top: 47px;
}

.view_8 {
  margin-top: 45px;
}

.space-y-26>*:not(:first-child) {
  margin-top: 26px;
}

.text_13 {
  margin-left: 4px;
}

.group_16 {
  margin-top: 47px;
}

.space-y-27>*:not(:first-child) {
  margin-top: 27px;
}

.text_14 {
  margin-left: 4px;
}

.text_15 {
  margin-left: 4px;
  margin-top: 160px;
  align-self: flex-start;
}

.group_17 {
  margin-left: 134px;
  margin-top: 62px;
  align-self: flex-start;
}

.space-y-47>*:not(:first-child) {
  margin-top: 47px;
}

.space-y-32>*:not(:first-child) {
  margin-top: 32px;
}

.text_16 {
  line-height: 17px;
}

.font_7 {
  font-size: 18px;
  
  line-height: 21px;
  font-weight: 300;
  color: #8b8b8b;
}

.group_18 {
  padding-left: 2px;
}

.space-y-29>*:not(:first-child) {
  margin-top: 29px;
}

.text_17 {
  margin-left: 3px;
  line-height: 22px;
}
.actives .divider{
  transform: translateX(-30%);
}
</style>